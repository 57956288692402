<template>
    <div class="wrapper">
        <template v-if="list.length==0">
            <v-empty type="coupon" />
            <!-- <v-loveGoodsList /> -->
        </template>
        <div
            class="list flex fd-c ai-c">
            <template v-for="(item, index) in list">
                <div
                    class="item flex ai-c"
                    :key="index"
                    :style="{
                        'background-image':
                            'url(/img/coupons/' + active + '.png)',
                    }"
                >
                    <div class="money flex fd-c ai-c jc-c" v-if="item.coupon.discountType==0">
                        <div class="t1">{{item.coupon.amount}}</div>
                        <div class="t2" v-if="item.coupon.minPoint!=0">满{{item.coupon.minPoint}}使用</div>
                        <div class="t2" v-else>无门槛</div>
                    </div>
                    <div class="money flex fd-c ai-c jc-c" v-if="item.coupon.discountType==1">
                        <div class="t3">{{item.coupon.proportion*10}}折</div>
                        <div class="t2" v-if="item.coupon.minPoint!=0">满{{item.coupon.minPoint}}使用</div>
                        <div class="t2" v-else>无门槛</div>
                    </div>
                    <div class="item-r flex fd-c jc-sb">
                        <div
                            class="name lines"
                            :style="active != 0 && 'color: #999999;'"
                        >
                            {{item.coupon.name}}
                        </div>
                        <div class="time">有效期至：{{item.endTime}}</div>
                    </div>
                    <div v-if="active == 0" @click="Click(item)" class="btn flex ai-c jc-c">
                        使用
                    </div>
                </div>
            </template>
        </div>
        <div class="kong" style="width: 100%;height: 1.3rem"></div>
        <div class="btn-box">
            <div class="btn-b" @click="cancel">不使用</div>
        </div>
    </div>
</template>

<script>
import loveGoodsList from "@/components/loveGoodsList";
export default {
    components: {
        "v-loveGoodsList": loveGoodsList,
    },
    props: {},
    data() {
        return {
            empty: false,
            active: 0,
            list: [],
        };
    },
    watch: {},
    computed: {},
    methods: {
        Click(item) {
            var orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
            orderInfo.couponId = item.id
            localStorage.setItem('orderInfo',JSON.stringify(orderInfo))
            this.$router.go(-1)
        },
        cancel() {
            var orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
            orderInfo.couponId = null
            localStorage.setItem('orderInfo',JSON.stringify(orderInfo))
            this.$router.go(-1)
        }
    },
    created() {
        this.list = JSON.parse(localStorage.getItem('couponList'))
    },
    mounted() {},
};
</script>
<style scoped>
.wrapper {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: rgba(238, 238, 238, 1);
}
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: #fff;
    z-index: 100;
}
.nav-item {
    position: relative;
    flex: 1;
    height: 100%;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
}
.active {
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #f35e3a;
}
.active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 0.55rem);
    width: 1.1rem;
    height: 0.04rem;
    background: #f35e3a;
    border-radius: 0.02rem;
}
.list {
    width: 100%;
    margin-top: .24rem;
}
.item {
    position: relative;
    width: 6.9rem;
    height: 1.73rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 0.27rem;
}
.money {
    width: 1.98rem;
    height: 1.73rem;
}
.money .t1 {
    font-size: 0.52rem;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
}
.money .t3 {
    font-size: 0.52rem;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
}
.money .t1::before {
    content: "¥";
    font-size: 0.34rem;
}
.money .t2 {
    font-size: 0.22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
}
.item-r {
    height: 100%;
    margin-left: 0.18rem;
}
.name {
    width: 4.5rem;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-top: .2rem;
}
.time {
    font-size: 0.2rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-bottom: 0.3rem;
}
.btn {
    position: absolute;
    right: 0.2rem;
    bottom: 0.25rem;
    width: 1rem;
    height: 0.4rem;
    border: 1px solid #f35e3a;
    border-radius: 0.2rem;
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f35e3a;
}
.btn-box{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: #fff;
}
.btn-box .btn-b{
    width: 7rem;
    height: .8rem;
    margin-top: .1rem;
    margin-left: calc(50% - 3.5rem);
    line-height: .8rem;
    text-align: center;
    background: #F35E3A;
    border-radius: .4rem;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-right: .3rem;
}
</style>